<template>
	<div class="content">
		<div class="header">
			<div class="title">{{ data.key === "last_meeting" ? $t("meeting_session.last_meeting") : $t("meeting_session.today_planning") }}</div>
			<slot name="header_menu_right"></slot>
		</div>

		<template v-if="loaded">
			<div class="body">
				<div class="container-form">
					<div class="table-list">
						<template v-for="(userId, indexUser) in users">
							<!-- header -->
							<div class="row-item header-category">
								<div class="column-name">
									{{ responseOrganigrama[userId].user.name }}
								</div>
								<div class="column-avatar">
									<img :src="responseOrganigrama[userId].user.avatar" v-if="responseOrganigrama[userId].user.avatar" />
									<div class="user-circle" v-else>
										<icon-user />
									</div>
								</div>
							</div>

							<!-- Promisiuni -->
							<template v-if="('standard', 'objective').includes($parent.meetingLog.topic_type)">
								<RowPromise
									:dataPromises="responsePromises"
									:userId="userId"
									@store-promise="storePromise($event, userId)"
									:key="`promise-${userId}-0`"
									v-if="
										$auth.check() &&
											data.key === 'planning_the_day' &&
											($parent.can_lead || userId == $auth.user().id) &&
											responsePromises[userId].daily_promise_status === 'promise_not_set'
									"
								/>
								<ReadPromise :dataPromises="responsePromises" :userId="userId" v-else />
							</template>

							<!-- Tasks -->
							<template v-if="responseOrganigrama[userId] && responseOrganigrama[userId].tasks">
								<template v-for="(taskLog, index) in responseOrganigrama[userId].tasks">
									<RowTask
										:task="taskLog"
										:index="index"
										:extraInfo="['update', 'organigrama', 'date', 'priority']"
										fromStep="today_planning"
										v-if="$auth.check() && ($parent.can_lead || userId == $auth.user().id)"
									/>
									<ReadTask
										:task="taskLog"
										:extraInfo="['organigrama', 'date', 'priority', 'planning_day']"
										fromStep="today_planning"
										v-else
									/>
								</template>
							</template>

							<!-- Tasks KR -->
							<template v-if="responseObjectives[userId] && responseObjectives[userId].tasks">
								<template v-for="(taskLog, index) in responseObjectives[userId].tasks">
									<RowTask
										:task="taskLog"
										:index="index"
										:extraInfo="['update', 'key_result', 'date']"
										fromStep="today_planning"
										v-if="$auth.check() && ($parent.can_lead || userId == $auth.user().id)"
									/>
									<ReadTask :task="taskLog" :extraInfo="['key_result', 'date']" fromStep="today_planning" v-else />
								</template>
							</template>

							<!-- Create Task -->
							<RowAddTask
								:userId="userId"
								:index="indexUser"
								@store-task="storeTask($event, userId)"
								:extraInfo="['priority']"
								v-if="$auth.check() && data.key === 'planning_the_day' && ($parent.can_lead || userId == $auth.user().id)"
							/>
						</template>
					</div>
					<slot name="form_submit"></slot>
				</div>
			</div>
		</template>
		<LoaderStepMeeting v-else />
	</div>
</template>

<script>
import IconTask from "@/components/Icons/Task";
import IconCheck from "@/components/Icons/Check";
import IconFlag from "@/components/Icons/Flag";
import IconPromise from "@/components/Icons/Promise";
import IconDots from "@/components/Icons/EditDots";
import IconCalendar from "@/components/Icons/Calendar";
import IconPlus from "@/components/Icons/Plus";

import RowTask from "@/components/ComponentsTask/RowTask";
import RowPromise from "@/components/ComponentsTask/RowPromise";
import RowAddTask from "@/components/ComponentsTask/RowAddTask";
import ReadPromise from "@/components/ComponentsTask/ReadPromise";
import ReadTask from "@/components/ComponentsTask/ReadTask";

import LoaderStepMeeting from "@/components/PagesLoaders/MeetingStep";

export default {
	components: {
		IconTask,
		IconCheck,
		IconFlag,
		IconPromise,
		IconCalendar,
		IconDots,
		RowTask,
		LoaderStepMeeting,
		IconPlus,
		RowPromise,
		RowAddTask,
		ReadPromise,
		ReadTask,
	},
	props: {
		optionsUsers: Array,
		data: Object,
	},
	data() {
		return {
			loaded: false,
			users: this.$parent.meetingLog.users_ids,
			responseOrganigrama: {},
			responseObjectives: {},
			responsePromises: {},
			loadedOrganigrama: false,
			loadedObjectives: false,
			loadedPromises: this.data.key === "planning_the_day" ? false : true,
		};
	},
	beforeDestroy() {
		this.$root.$off("updateTaskKeyResultMeeting");
		this.$root.$off("updateTaskMeeting");
	},
	async mounted() {
		this.getUsersTasksOrganigrama();
		this.getUsersTasksObjectives();

		if (this.data.key === "planning_the_day") {
			this.getUsersPromises();
		}
		// interval that check if the axios calls are finished
		var interval = setInterval(() => {
			if (this.loadedOrganigrama && this.loadedObjectives && this.loadedPromises) {
				this.loaded = true;
				clearInterval(interval);
			}
		}, 1000);

		this.$root.$on("updateTaskKeyResultMeeting", (taskData) => {
			this.updateOrCreateTaskObjFromWs(taskData);
		});

		this.$root.$on("updateTaskMeeting", (taskData) => {
			this.updateOrCreateTaskOrgFromWs(taskData);
		});

		this.$root.$on("updatePromiseMeeting", (promiseData) => {
			this.updateOrCreatePromise(promiseData);
		});
	},
	methods: {
		getUsersTasksOrganigrama() {
			var paramsQuery = {
				users_ids: this.users,
			};

			if (this.data.key === "last_meeting") {
				paramsQuery.start_interval = this.$parent.meetingLog.last_meeting_date;

				// get yesterday date
				var yesterday = moment()
					.subtract(1, "days")
					.format("YYYY-MM-DD");
				// check if yesterday is less than last_meeting_date
				if (moment(yesterday).isBefore(this.$parent.meetingLog.last_meeting_date)) {
					paramsQuery.end_interval = this.$parent.meetingLog.last_meeting_date;
				} else {
					paramsQuery.end_interval = yesterday;
				}
			} else {
				paramsQuery.date = moment().format("YYYY-MM-DD");
			}

			if (this.$parent.meetingLog == "project" && this.$parent.meetingTopic.some((el) => el.type === "project")) {
				// Get all projects ids on an array
				var projectsIds = this.$parent.meetingTopic.filter((el) => el.type === "project").map((el) => el.id);
				paramsQuery.projects_ids = projectsIds;
			}

			axios
				.get(`/public/${this.$parent.masterInstanceId}/meeting-session/${this.$route.params.uuid}/users-tasks`, { params: paramsQuery })
				.then(({ data }) => {
					this.responseOrganigrama = data.data;
				})
				.finally(() => {
					this.loadedOrganigrama = true;
				});
		},
		getUsersTasksObjectives() {
			var paramsQuery = {
				users_ids: this.users,
			};

			if (this.data.key === "last_meeting") {
				paramsQuery.start_interval = this.$parent.meetingLog.last_meeting_date;

				// get yesterday date
				var yesterday = moment()
					.subtract(1, "days")
					.format("YYYY-MM-DD");
				// check if yesterday is less than last_meeting_date
				if (moment(yesterday).isBefore(this.$parent.meetingLog.last_meeting_date)) {
					paramsQuery.end_interval = this.$parent.meetingLog.last_meeting_date;
				} else {
					paramsQuery.end_interval = yesterday;
				}
			} else {
				paramsQuery.date = moment().format("YYYY-MM-DD");
			}

			if (this.$parent.meetingTopic.some((el) => el.type === "objective")) {
				// Get all objectives ids on an array
				var objectivesIds = this.$parent.meetingTopic.filter((el) => el.type === "objective").map((el) => el.id);
				paramsQuery.objectives_ids = objectivesIds;
			}

			if (this.$parent.meetingTopic.some((el) => el.type === "key_result")) {
				// Get all key_results ids on an array
				var keyResultsIds = this.$parent.meetingTopic.filter((el) => el.type === "key_result").map((el) => el.id);
				paramsQuery.key_results_ids = keyResultsIds;
			}

			axios
				.get(`${process.env.VUE_APP_OBJECTIVE_LINK}/api/public/${this.$parent.masterInstanceId}/users-tasks`, { params: paramsQuery })
				.then(({ data }) => {
					this.responseObjectives = data.data;
				})
				.finally(() => {
					this.loadedObjectives = true;
				});
		},
		getUsersPromises() {
			var paramsQuery = {
				users_ids: this.users,
			};

			axios
				.get(`${process.env.VUE_APP_OBJECTIVE_LINK}/api/public/${this.$parent.masterInstanceId}/users-daily-promises`, { params: paramsQuery })
				.then(({ data }) => {
					this.responsePromises = data.data;
				})
				.finally(() => {
					this.loadedPromises = true;
				});
		},

		changeStepFunction(direction, stepNo = false) {
			if (direction) {
				this.$emit(direction);
			} else if (stepNo) {
				this.$emit("goToStep", stepNo);
			}
		},
		changeStepOrRedirect(changeStep, withRedirect, userSlug) {
			if (changeStep) {
				this.$nextTick(() => {
					if (!changeStep.step) {
						this.$emit(changeStep.direction);
					} else {
						this.$emit("goToStep", changeStep.step);
					}
				});
			}

			if (withRedirect) {
				if (this.$route.query.department_id) {
					this.$router.push({ name: "company" });
				} else {
					this.$router.push({ name: "user-show", params: { slug: userSlug } });
				}
			}
		},
		storePromise(dataForm, userId) {
			let keyResultsIds = dataForm.key_results.map((el) => el.id);
			var formData = {
				user_id: userId,
				name: dataForm.title,
				key_result_ids: keyResultsIds,
			};

			axios.post(`${process.env.VUE_APP_OBJECTIVE_LINK}/api/promises/store`, formData).then(({ data }) => {
				const newPromisObj = {
					daily_promise_status: "has_promise",
					today_promise: {
						id: data.data.id,
						name: dataForm.title,
						key_results_names: this.responsePromises[userId].user_key_results
							.filter((el) => keyResultsIds.includes(el.id))
							.map((el) => {
								return el.name;
							}),
						status: "pending",
						private_key_results: 0,
					},
				};

				Object.assign(this.responsePromises[userId], newPromisObj);

				this.$parent.socket.send(
					JSON.stringify({
						entity_id: this.$parent.meetingLog.id,
						entity_user_id: this.$auth.user().id,
						type: "meeting",
						custom_data: {
							type: "promise",
							data: {
								action: "store",
								promise_user_id: userId,
								...newPromisObj,
							},
						},
					})
				);
			});
		},
		storeTask(data, userId) {
			var formData = {
				name: data.name,
				user_id: userId,
				from_meeting_id: this.$parent.meetingLog.id,
				from_meeting_step: "today_planning",
			};

			if (data.priority != "") {
				formData.priority = data.priority;
			}

			if (this.$parent.meetingLog.topic_type === "project") {
				formData.project_id = this.$parent.meetingTopic.find((el) => el.type === "project").id;
			}

			if (data.hasOwnProperty("date") && data.date != "") {
				formData.date = data.date;
				formData.deadline = data.date;
				formData.start_date = data.date;
			} else {
				formData.date = moment().format("YYYY-MM-DD");
				formData.deadline = moment().format("YYYY-MM-DD");
				formData.start_date = moment().format("YYYY-MM-DD");
			}

			axios.post(`/tasks/store`, formData).then(({ data }) => {
				if (!this.responseOrganigrama[userId].tasks) {
					this.responseOrganigrama[userId].tasks = [];
				}

				this.responseOrganigrama[userId].tasks.push({
					date: formData.date,
					done: 0,
					id: data.data.task_log_id,
					name: formData.name,
					priority: formData.priority,
					task_id: data.data.id,
					type: "task_log",
					user_id: formData.user_id,
				});
			});
		},
		updateOrCreateTaskObjFromWs(taskData) {
			switch (taskData.action) {
				case "store":
					if (this.responseObjectives.hasOwnProperty(taskData.user_id)) {
						if (!this.responseObjectives[taskData.user_id].tasks) {
							this.responseObjectives[taskData.user_id].tasks = [];
						}

						this.responseObjectives[taskData.user_id].tasks.push({ ...taskData, task_id: taskData.id });
					}
				case "update":
					const foundTask = this.findTaskById(taskData, this.responseObjectives, "objective");

					if (foundTask) {
						Object.assign(foundTask.data, {
							name: taskData.name,
							deadline: taskData.deadline,
							done: taskData.done,
						});
					}
					break;
			}
		},
		updateOrCreateTaskOrgFromWs(taskData) {
			switch (taskData.action) {
				case "store":
					if (taskData.responsible && taskData.responsible.id) {
						if (!this.responseOrganigrama[taskData.responsible.id].tasks) {
							this.responseOrganigrama[taskData.responsible.id].tasks = [];
						}

						this.responseOrganigrama[taskData.responsible.id].tasks.push({ ...taskData, task_id: taskData.id });
					}
					break;
				case "update":
					const foundTask = this.findTaskById(taskData, this.responseOrganigrama, "organigrama");

					if (foundTask) {
						var newObjTask = {
							id: taskData.id,
							task_id: taskData.id,
							name: taskData.name,
							date: taskData.date,
							deadline: taskData.deadline,
							done: taskData.done,
							frequency: taskData.frequency,
							priority: taskData.priority,
							responsible: taskData.responsible,
						};

						if (taskData.date != moment().format("YYYY-MM-DD")) {
							this.responseOrganigrama[foundTask.currentUserId].tasks.splice(foundTask.index, 1);
						}

						if (foundTask.currentUserId == taskData.responsible.id) {
							Object.assign(foundTask.data, newObjTask);
						} else {
							// Trebuie sters din
							this.responseOrganigrama[foundTask.currentUserId].tasks.splice(foundTask.index, 1);

							// si adaugat in cel nou
							var newTask = { ...Object.assign(foundTask.data, newObjTask) };
							if (!this.responseOrganigrama[taskData.responsible.id].tasks) {
								this.responseOrganigrama[taskData.responsible.id].tasks = [];
							}

							this.responseOrganigrama[taskData.responsible.id].tasks.push(newTask);
						}
					}
					break;
				case "destroy":
					// Found task and delete it
					const foundTaskForDelete = this.findTaskById(taskData, this.responseOrganigrama, "organigrama");

					if (foundTaskForDelete) {
						this.responseOrganigrama[foundTaskForDelete.currentUserId].tasks.splice(foundTaskForDelete.index, 1);
					}
					break;
			}
		},
		updateOrCreatePromise(promiseData) {
			switch (promiseData.action) {
				case "store":
					if (this.responsePromises.hasOwnProperty(promiseData.promise_user_id)) {
						Object.assign(this.responsePromises[promiseData.promise_user_id], {
							daily_promise_status: promiseData.daily_promise_status,
							today_promise: promiseData.today_promise,
						});
					}
					break;
				case "update":
					// Vedem ce facem
					break;
			}
		},
		findTaskById(taskData, data, type) {
			for (const key in data) {
				if (data[key].tasks) {
					var taskIndexFound = "";
					var taskFound = "";

					if (type == "objective") {
						taskIndexFound = data[key].tasks.findIndex((task) => task.id === taskData.task_id);
					} else {
						taskIndexFound = data[key].tasks.findIndex(
							(task) =>
								task.task_id === taskData.id &&
								(task.date === taskData.date || (taskData.hasOwnProperty("old_task_log_date") && task.date === taskData.old_task_log_date))
						);
					}

					if (taskIndexFound != -1) {
						taskFound = data[key].tasks[taskIndexFound];
						return { data: taskFound, currentUserId: key, index: taskIndexFound };
					}
				}
			}
			return null;
		},
	},
};
</script>
